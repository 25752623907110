import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Container from 'typedi'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import { mapState } from 'vuex'

@Component({
  name: 'MultiFactorMixin',
  computed: {
    ...mapState('security', ['currentUser'])
  }
})
export default class MultiFactorMixin extends Vue {
  currentUser!: Record<string, any>
  two_factor_token: string | null = null
  valid_two_factor_token = false
  prompt_two_factor = false
  verifying = false

  async sendTwoFactorCode (two_factor_purpose: string) {
    try {
      this.$data.loading = true
      this.$store.dispatch('security/send2FAToken', two_factor_purpose)
      Container.get(Notification).success('Two factor code sent successfully.')
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async verifyToken () {
    try {
      this.$data.loading = true
      this.verifying = true
      if (!this.two_factor_token) {
        Container.get(Notification).error('Please enter a two factor code.')
        return
      }
      await this.$store.dispatch('security/verify2FACode', this.two_factor_token)
      Container.get(Notification).success('Two factor code verified successfully.')
      this.valid_two_factor_token = true
      this.two_factor_token = null
    } catch (error) {
      if (error.data.error_code === 'WRONG_TOKEN') {
        Container.get(Notification).error(error.data.error_message)
      } else {
        Container.get(ErrorHandlerService).error(error)
      }
    } finally {
      this.verifying = false
      this.$data.loading = false
    }
  }
}
