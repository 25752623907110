import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import GtrGlobalSolutionLayout from '@/modules/common/views/layouts/level-two/global-solution/global-solution.layout.vue'
import Container from 'typedi'
import { Component, Mixins } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrNewUserForm from './forms/new/new-user.form.vue'
import MultiFactorMixin from '@/modules/common/components/mixins/multifactor.mixin'
import type { GtrConfirmationActionPayload } from '@/modules/common/components/ui-core/gtr-confirmation/gtr-confirmation'

@Component({
  name: 'GtrCompanyUsersView',
  computed: {
    ...mapState('company', ['company']),
    ...mapState('security', ['currentUser'])
  },
  components: {
    'gtr-new-company-user-form': GtrNewUserForm
  }
})
export default class GtrCompanyUsersView extends Mixins(GtrSuper, MultiFactorMixin) {
  company!: any;

  currentUser!: any;

  selected: any[] = [];

  message = 'Are you sure you want do this?'
  action: Function | null = null
  confirm = false
  two_factor_purpose = ''

  headers = [
    {
      text: 'Name',
      value: 'name'
    },
    {
      text: 'Email',
      value: 'email'
    },
    {
      text: 'Status',
      value: 'status'
    },
    {
      text: 'Access Level',
      value: 'access_level'
    },
    {
      text: 'Last Active',
      // TODO: change value to last_active once added to user table.
      value: 'updated_at'
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ];

  sendingEmails = false;

  updatingStatus = false;

  granting = false;

  data () {
    return {
      search: '',
      loading: false,
      users: [],
      forms: {
        showNewCompanyUserForm: false,
        showEventAccessForm: false
      },
      // headers: [
      //   {
      //     text: 'Name',
      //     align: 'start',
      //     sortable: true,
      //     value: 'name'
      //   },
      //   {
      //     text: 'Email',
      //     value: 'email',
      //     sortable: false
      //   },
      //   { text: 'Access Level', value: 'access_level', sortable: false },
      //   {
      //     text: '',
      //     value: 'actions',
      //     searchable: false,
      //     sortable: false,
      //     width: '90px'
      //   }
      // ],
      userToDelete: null,
      userToCorrelate: null
    }
  }

  created () {
    this.$emit('update:layout', GtrGlobalSolutionLayout)
    this.$bus.$on('gtr.level-two.new-user', this.handleAction)
    this.$bus.$on('gtr.level-two.edit-user', this.handleAction)
  }

  async mounted () {
    await this.fetchCompanyUsers()
  }

  async fetchCompanyUsers () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('company/fetchCompany', this.$route.params.uuid)
      this.$data.users = this.$store.state.company.company.admin_users

      // Update selection objects if present
      if (this.selected.length) {
        for (let selection of this.selected) {
          for (const user of this.$data.users) {
            if (selection.uuid === user.uuid) {
              selection = Object.assign(selection, user)
            }
          }
        }
      }
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  formatDate (date: string): string {
    if (this && this.$options && this.$options.filters) {
      return this.$options.filters.formatDateAndTime(date, null, 'MM/DD/YYYY hh:mm A')
    }
    return date
  }

  async handleExportUsers () {
    try {
      this.$data.loading = true
      const response = await this.$store.dispatch('adminUser/exportUsers', { company_uuid: this.$route.params.uuid })
      this.checkURLAndGo(response.data)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleShowNewCompanyUserForm () {
    this.$data.forms.showNewCompanyUserForm = true
    this.valid_two_factor_token = false
    if (this.currentUser.two_factor_enabled || this.currentUser.two_factor_enabled !== 0) {
      this.sendTwoFactorCode('CREATE_USER')
    }
  }

  handleCloseNewCompanyUserForm () {
    this.$data.forms.showNewCompanyUserForm = false
    this.valid_two_factor_token = false
  }

  handleCloseEventCorrelatorForm () {
    this.$data.forms.showEventAccessForm = false
  }

  emitLoadEventAccess (payload: boolean) {
    if (payload) {
      this.$bus.$emit('gtr-event-access-load', this.$data.userToCorrelate)
    }
  }

  async impersonateUser (user) {
    try {
      this.$data.loading = true
      await this.$store.dispatch('security/impersonateUser', user.uuid)
      this.$router.go(0)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async handleAction () {
    await this.fetchCompanyUsers()
  }

  async updateUserStatus (userUuid: string, val: boolean) {
    await this.$store.dispatch('adminUser/updateUserStatus', {
      user_uuid: userUuid,
      active: val
    })
  }

  async handleSingleUpdateUserStatus (user: Record<string, any>, newActiveStatus: boolean): Promise<void> {
    try {
      this.updatingStatus = true
      await this.updateUserStatus(user.uuid, newActiveStatus)
      await this.fetchCompanyUsers()
      Container.get(Notification).success(`User successfully ${newActiveStatus ? 'activated' : 'deactivated'}.`)
    } catch (error) {
      Container.get(Notification).success(`There was an error ${newActiveStatus ? 'activating' : 'deactivating'} the user.`)
    } finally {
      this.updatingStatus = false
    }
  }

  get confirmationMessage () {
    return this.message
  }

  set confirmationMessage (message: string) {
    this.message = message
  }

  get confirmationAction () {
    return this.action
  }

  set confirmationAction (action: Function | null) {
    this.action = action
  }

  /**
   * Handles the action event from gtr-confirmation component.
   * @param {GtrConfirmationActionPayload} payload - The payload from the gtr-confirmation component.
   */
  handleConfirmationAction (payload: GtrConfirmationActionPayload) {
    if (payload.confirm && this.confirmationAction) {
      this.confirmationAction()
    }
    this.confirm = false
    this.valid_two_factor_token = false
  }

  /**
   * confirmAction configures the parameters for the confirmation dialog.
   * @param {string} message - The message to display in the confirmation dialog.
   * @param {string} two_factor_purpose - The purpose for the 2FA
   * @param {Function | null} action - optional action to execute when the user confirms the action.
   * @param {any[] | null} action_args - optional arguments to pass to the action.
   */
  async confirmAction (message: string, two_factor_purpose: string, action: Function | null = null, action_args: any[] | null = null) {
    try {
      this.confirm = true
      this.two_factor_purpose = two_factor_purpose
      this.confirmationMessage = message
      if (action && action_args) {
        this.confirmationAction = action.bind(this, ...action_args)
      } else if (action) {
        this.confirmationAction = action
      } else {
        this.confirmationAction = null
      }
      if (this.currentUser.two_factor_enabled || this.currentUser.two_factor_enabled !== 0) {
        await this.sendTwoFactorCode(two_factor_purpose)
      }
    } catch (error) {
      Container.get(Notification).error(`Error: ${(error as Error).message}`)
    }
  }

  async handleUpdateUserStatus (newActiveStatus: boolean) {
    try {
      if (this.selected.length > 0) {
        this.updatingStatus = true
        for (const selection of this.selected) {
          if (((selection.active === 1 || selection.active === true) && newActiveStatus) ||
            ((selection.active === 0 || selection.active === false) && !newActiveStatus)) {
            throw new Error(`User ${selection.name} is already ${newActiveStatus ? 'active' : 'inactive'}`)
          }
          await this.updateUserStatus(selection.uuid, newActiveStatus)
        }
        await this.fetchCompanyUsers()
        Container.get(Notification).success(`User(s) successfully ${newActiveStatus ? 'activated' : 'deactivated'}.`)
      } else {
        throw new Error('No users selected.')
      }
    } catch (error) {
      Container.get(Notification).error(`Error ${newActiveStatus ? 'activating' : 'deactivating'} account(s): ${(error as Error).message}`)
    } finally {
      this.updatingStatus = false
    }
  }

  async handleSingleMakeAdmin (user: Record<string, any>): Promise<void> {
    try {
      this.granting = true
      await this.$store.dispatch('adminUser/updateUser', {
        user_uuid: user.uuid,
        data: {
          access_level: 'COMPANY_ADMIN'
        }
      })
      await this.fetchCompanyUsers()
      Container.get(Notification).success('User granted admin priveleges.')
    } catch (error) {
      Container.get(Notification).error('There was an error elevating priveleges of user.')
    } finally {
      this.granting = false
    }
  }

  async handleMakeAdmin () {
    try {
      this.granting = true
      if (this.selected.length > 0) {
        for (const selection of this.selected) {
          if (selection.access_level === 'COMPANY_ADMIN') {
            throw new Error(`User ${selection.name} already an admin.`)
          }
          await this.$store.dispatch('adminUser/updateUser', {
            user_uuid: selection.uuid,
            data: {
              access_level: 'COMPANY_ADMIN'
            }
          })
          await this.fetchCompanyUsers()
        }
        Container.get(Notification).success('Make admin: User(s) granted new priveleges')
      } else {
        throw new Error('No users selected')
      }
    } catch (error) {
      Container.get(Notification).error(`Make admin error: ${(error as Error).message}`)
    } finally {
      this.granting = false
    }
  }

  async handleSinglePasswordReset (user: Record<string, any>): Promise<void> {
    try {
      this.sendingEmails = true
      await this.$store.dispatch('security/resetPassword', { email: user.email })
      Container.get(Notification).success('Password reset email sent.')
    } catch (error) {
      Container.get(Notification).error(`Password reset: ${(error as Error).message}`)
    } finally {
      this.sendingEmails = false
    }
  }

  async handlePasswordReset () {
    try {
      if (this.selected.length > 0) {
        this.sendingEmails = true
        const emails = this.selected.map(selection => selection.email)
        // TODO: filter out empty or undefined emails.
        for (const email of emails) {
          await this.$store.dispatch('security/resetPassword', { email })
        }
        Container.get(Notification).success('Password reset email sent.')
      } else {
        throw new Error('No users selected')
      }
    } catch (error) {
      Container.get(Notification).error(`Password reset: ${(error as Error).message}`)
    } finally {
      this.sendingEmails = false
    }
  }
}
